import React from "react";
import { Carousel, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { carouselData } from "../data";

const CarouselCustom = () => {
	const user = useSelector(({ user }) => user);
	const config = useSelector((state) => state.app.config);
	return (
		<Container className="carouselCustom" fluid>
			<div className="row justify-content-center" id="home">
				<Carousel>
					{carouselData.map((item, index) => {
						return (
							<>
								{!user.accessToken && config.campaignState !== "partialExpired" ? (
									<a href="/register" className="w-100">
										<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
										<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
									</a>
								) : (
									<>
										<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
										<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
									</>
								)}
							</>
						);
					})}
				</Carousel>
			</div>
		</Container>
	);
};

export default CarouselCustom;
