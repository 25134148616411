import React from "react";
import { Container } from "react-bootstrap";
//import {footerCopyRight} from "../data";
import { useTranslation } from "react-i18next";

const CopyRight = () => {
	const { t } = useTranslation();
	return (
		<div className="row">
			<div className="col-sm-12">
				<div className="copy-text">
					<div>
						Purchase must be made in a single transaction. While supplies last. Available only to the first 1,055 eligible claimants. Must be a legal resident of CA, IA, IL, IN, KY, MI, MN, NV, TN, or WI who is 18 years of age or older as of the last day of the month prior to date of Offer redemption. All purchases must be made between 12:00 a.m. ET on 11/13/23 and 11:59 p.m. ET on
						01/03/24. There is no limit to the number of rewards per person/email address, but each claim must be unique. Valid while supplies last. Void where prohibited or restricted by law. Visit{" "}
						<a href="https://www.CokeHolidayGift.com/" style={{ color: "#fff" }} target="_blank">
							<u>www.CokeHolidayGift.com</u>
						</a>{" "}
						for full Terms and Conditions. Reward code must be claimed by 01/15/2024.
					</div>
					<p className="tierlogic">
						{t("Powered By ")}
						<a href="https://www.3tierlogic.com/" target="_blank">
							<img alt="3TL-logo" src="/public/images/3tl-logo-footer.png" />
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default CopyRight;
