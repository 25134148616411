import React from "react";
import CarouselCustom from "../../components/CarouselCustom";
import ParticipateInstructions from "../../components/ParticipateInstructions";
import IsLoadingHOC from "../../components/IsLoadingHOC";
const Home = (props) => {
	return (
		<>
			<CarouselCustom />
			<ParticipateInstructions />
		</>
	);
};

export default IsLoadingHOC(Home, "");
