import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert, Section, } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import IsLoadingHOC from "./IsLoadingHOC";
import { helpQueryAction } from "../redux/actions";
import { toast } from "react-toastify";
import Captcha from "./CaptchaClick";
import { useSelector } from "react-redux";
import Required from "../components/Required";
const Support = (props) => {
	const { t } = useTranslation();
	const [data, setData] = useState({
		name: "",
		email: "",
		description: "",
		captchaVal: "",
	});
	const config = useSelector((state) => state.app.config);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	const [error, setError] = useState({ name: "", email: "", description: "", captchaVal: "" });
	// eslint-disable-next-line
	const validationError = { description: "", name: /^[A-Za-z]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, captchaVal: "" };
	let [captchaEnable, setCaptchaEnable] = useState(false);
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captchaVal"] = "";
			let reqd = JSON.parse(JSON.stringify(data));
			reqd.captchaVal = captchaValue;
			setData(reqd);
		}
		setError(errorArray);
		// eslint-disable-next-line
	}, [captchaValue]);
	useEffect(() => {
		if (config.captchaConfig) {
			if (config.captchaConfig.supportCaptcha) {
				setCaptchaEnable(true);
			}
		}
	}, [config]);
	const handleChange = (e) => {
		let key = e.target.name;
		let val = e.target.value;
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		if (val.match(validationError[key]) || val === "" || key === "email") {
			reqd[key] = val;
		}
		if (key === "name") {
			if (!val || val.trim().length < 1) {
				errd[key] = `Please enter your name.`;
			} else {
				errd[key] = ``;
			}
		} else if (key === "email") {
			if (!val.match(validationError[key])) {
				errd[key] = `Please enter a valid email address`;
			} else {
				errd[key] = ``;
			}
		} else if (key === "description") {
			if (!val || val.trim().length < 1) {
				errd[key] = `Message is required`;
			} else {
				errd[key] = ``;
			}
		}
		setError(errd);
		setData(reqd);
	};
	const onSubmit = () => {
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		let isError = false;
		for (let key in reqd) {
			let val = reqd[key].trim();
			if (key === "name") {
				if (!val || val.length < 1) {
					isError = true;
					errd[key] = `Please enter your name.`;
				} else {
					errd[key] = ``;
				}
			} else if (key === "email") {
				if (!val.match(validationError[key])) {
					errd[key] = `Please enter a valid email address.`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			} else if (key === "description") {
				if (!val || val.length < 1) {
					errd[key] = `Message is required`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			} else if (key === "captchaVal" && captchaEnable) {
				if (!val || val.length < 2) {
					errd[key] = `Please check Captcha`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			}
		}
		setError(errd);
		if (!isError) {
			props.setLoading(true);
			let requestData = { email: reqd.email, name: `${reqd.name} `, message: reqd.description };
			if (captchaEnable) {
				requestData["captchaVal"] = reqd.captchaVal;
			}
			helpQueryAction(requestData)
				.then((response) => {
					for (let key in reqd) {
						reqd[key] = "";
					}
					setResetCaptchaValue(resetCaptchaValue + 1);
					setData(reqd);
					props.setLoading(false);
					toast.success(`Thank you! All messages are responded to within 3 business days in the order they are received.`);
				})
				.catch((error) => {
					toast.error(t("Something went wrong"));
					props.setLoading(false);
				});
		}
	};
	return (
		<Container className="justify-content-md-center" id="support-content">
			<section role="contentinfo" aria-label="Support" className="register-page">
				<Form>
					<h1 className="main-heading text-center">{t("Support")}</h1>
					<p className="text-center small">
						The customer support representative will be available via email
						to offer support to users experiencing issues with navigating
						the site, uploading images to the microsite, and receiving
						appropriate emails. This will be done through the email{' '}
						<a href="mailto:support@receiptprocessor.com">
							support@receiptprocessor.com
						</a>{' '}
						unless otherwise stated. Support will be provided in English.
					</p>

					<Row className="justify-content-md-center">
						<Col md={9}>
							<Row className="mt-5">
								{/*[START:name]*/}
								<div className="form-group col-sm-6 theme-signup-name">
									<label htmlFor="name" className="col-form-label">
										<Trans>Name</Trans>
										<Required />
									</label>
									<div className="">
										<input type="text" data-test="register-name" value={data.name} name="name" onChange={handleChange} className="form-control" id="name" />
										<div className="input-error">{error.name}</div>
									</div>
								</div>
								{/*[END:name]*/}

								{/*[START:Email]*/}
								<Col className="form-group theme-profile-email">
									<label htmlFor="email" className="col-form-label">
										<Trans>lang_key_email</Trans>
										<Required />
									</label>
									<div className="">
										<input type="email" data-test="profile-email" name="email" className="form-control" value={data.email} onChange={handleChange} id="email" />
										<div className="input-error">{error.email}</div>
									</div>
								</Col>
								{/*[END:Email]*/}
							</Row>

							<Row md={1}>
								<Col sm={12} className="form-group theme-signup-email ">
									<label className="col-form-label" htmlFor="supportmsg">Message <Required /> </label>
									<Form.Control size="lg" as="textarea" id="supportmsg" value={data.description} onChange={handleChange} name="description" style={{ height: "80px" }} onFocus={handleChange}
									/>
									<div className="input-error">{error.description}</div>
								</Col>
							</Row>
							{captchaEnable ? (
								<Row md={1}>
									<Col>
										<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">Google captcha</label>
										<Captcha align="left" reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
										<div className="input-error text-left">{error.captchaVal}</div>
									</Col>
								</Row>
							) : (
								""
							)}
							<Row md={1}>
								<Col className="text-center">
									<Button variant="primary" className="btn btn-primary" id="register-submit-btn" data-test="register-submit" onClick={onSubmit}>
										<Trans>Submit</Trans>
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</section>
		</Container>
	);
};

export default IsLoadingHOC(Support, "Wait .....");
