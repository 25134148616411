import React from "react";
import { Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

const ParticipateInstructions = () => {
	const config = useSelector((state) => state.app.config);
	if (config.campaignState === "partialExpired") {
		return (
			<Row className="justify-content-md-center section-content" id="participate-content">
				<Col md={12} className="section-content">
					<h1 className="text-center">SORRY, THIS PROMOTION HAS ENDED.</h1>
				</Col>
			</Row>
		);
	} else {
		return (
			<div id="participate-content">
				<Row className="justify-content-md-center section-content" aria-label="content">
					<Col md={11}>
						<h1 className="section-heading text-center">How To Participate</h1>
					</Col>
					<Col md={11} className="text-center">
						<h2 className="htp-heading">BUY</h2>
						<p className="htp-content">Purchase $20 of participating products between 11/13/2023 and 01/03/2024 and register.</p>
						<h2 className="htp-heading">TAKE A PICTURE</h2>
						<p className="htp-content">Snap a photo of your receipt showing your qualifying purchase(s). Don’t forget that the photo of each receipt must display the date and time of the transaction and the qualifying products purchased. If the receipt is too long, please fold the receipt so that the date and time and qualifying purchases are included in the picture.</p>
						<h2 className="htp-heading">UPLOAD</h2>
						<p className="htp-content">
							To upload your photo of your receipt, click the “SUBMIT” button and follow the instructions. Look out for an email confirming your receipt was uploaded and received. Receipts can take up to one to two business days to process and will be subject to verification before an approval will be received, so please be patient. All eligible receipts MUST be uploaded by
							01/10/2024.
						</p>
						<div className="text-center">
							<a href="/register" className="btn btn-primary">
								<Trans>lang_key_joinNow</Trans>
							</a>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
};

export default ParticipateInstructions;
