import React from "react";
import { Route, Switch } from "react-router-dom";
import AppRoute from "./AppRoute";
import PublicLayout from "../components/layout/PublicLayout";
import Register from "../screen/Signup/Register";
import Home from "../screen/Home";
import PrivateRoute from "./PrivateRoute";
import UploadReceipt from "../screen/ValidatePurchase/UploadReceipt";
import Upcoming from "../screen/CampaignStatus/Upcoming";
import Expired from "../screen/CampaignStatus/Expired";
import SupportScreen from "../screen/SupportScreen";
import FaqScreen from "../screen/FaqScreen";
const routes = (
	<>
		<Switch>
			<AppRoute exact path="/" component={Home} layout={PublicLayout} />
			<AppRoute exact path="/register" component={Register} layout={PublicLayout} />
			<PrivateRoute exact path="/upload-receipt" component={UploadReceipt} layout={PublicLayout} />
			<Route exact path="/upcoming" component={Upcoming} />
			<Route exact path="/expired" component={Expired} />
			<AppRoute exact path="/support" component={SupportScreen} layout={PublicLayout} />
			<AppRoute exact path="/faq" component={FaqScreen} layout={PublicLayout} />
			<AppRoute path="*" component={Home} layout={PublicLayout} />
		</Switch>
	</>
);
export default routes;
