import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Button, FormGroup, CloseButton } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { toast } from "react-toastify";
import { uploadImageAction, validateUploadReceiptAction } from "../../redux/actions";
import PreviousReceiptList from "./PreviousReceiptList";
import FileDropZone from "../FileDropZone";
import { useSelector } from "react-redux";
import Captcha from "../CaptchaClick";
import { generatePdfFromImages } from "../../hooks/ImagesToPDF";
import { useHistory } from "react-router-dom";
const UploadReceipt = (props) => {
	const { t } = useTranslation();
	const config = useSelector((state) => state.app.config);
	const [show, setShow] = useState(false);
	const [modalContentType, setModalContentType] = useState(0);
	const [formAddData, setFormAddData] = useState({ additionalInfo: { agree_to_program_scope: false, parseWithOcr: false } });
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	let [selectedFile, setSelectedFile] = useState([]);
	const [receiptLoadCounter, setReceiptLoadCounter] = useState(1);
	let [enableUploading, setEnableUploading] = useState(true);
	const history = useHistory();
	React.useEffect(() => {
		if ((config?.captchaConfig?.recaptchaVersion || "").toLowerCase() === "v3") {
			const interval = setInterval(() => {
				setResetCaptchaValue(resetCaptchaValue + 1);
			}, 45000);
			return () => clearInterval(interval);
		}
	}, [resetCaptchaValue, config]);
	const handleClose = () => {
		setShow(false);
		resetAllSelectionBtnClick();
	};
	const handleShow = () => {
		setModalContentType();
		setShow(true);
	};
	React.useEffect(() => {
		if (config.campaignState === "partialExpired") {
			history.push("/home");
			toast.error(`Sorry! You are not allowed to access this page. Promotion is ended.`);
		}
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(false);
		}
		if (config.captchaConfig) {
			if (config.captchaConfig.uploadReceiptCaptcha) {
				setCaptchaEnable(true);
			}
		}
		//eslint-disable-next-line
	}, [config]);
	const checkUploadEnableOrNot = (uploadApiCall) => {
		props.setLoading(true);
		validateUploadReceiptAction({})
			.then((responseData) => {
				props.setLoading(false);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				if (responseData["httpCode"] >= 200 && responseData["httpCode"] <= 299) {
					setEnableUploading(responseData.data.valid);
					if (uploadApiCall) {
						uploadFileHandler(responseData.data.token);
					}
					return true;
				} else {
					toast.error(`${t("Error")}! ${t("You have reached the program limit for rewards")}.`);
				}
			})
			.catch((err) => {
				console.log("error", err);
				toast.error(`${t("Error")}! ${t("You have reached the program limit for rewards")}.`);
				props.setLoading(false);
			});
	};
	const uploadImage = () => {
		if (selectedFile.length === 0) {
			toast.error(`${t("Error")}! ${t("Select a receipt")}`);
			return false;
		}
		if (!captchaValue && captchaEnable) {
			toast.error(`${t("Error")}! ${t("Please select captcha")}`);
			return false;
		}
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(true);
		} else {
			uploadFileHandler("");
		}
	};
	const popupUploadBtnHandler = () => {
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(true);
		} else {
			uploadFileHandler("");
		}
	};
	const uploadFileHandler = async (token) => {
		let fileData = selectedFile[0];
		if (selectedFile.length > 1) {
			fileData = await generatePdfFromImages(selectedFile);
		}
		const formData = new FormData();
		formData.append("type", "receipt");
		formData.append("image", fileData);
		if (token) {
			formData.append("token", token);
		}
		if (captchaEnable) {
			formData.append("captchaVal", captchaValue);
		}
		props.setLoading(true);
		uploadImageAction(formData)
			.then((responseData) => {
				props.setLoading(false);
				setResetCaptchaValue(resetCaptchaValue + 1);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				setCaptchaValue("");
				if (responseData["httpCode"] === 200) {
					toast.success("Your receipt image(s) have been uploaded successfully. Please allow up to one to two business days for validation.");
					setSelectedFile([]);
					return true;
				} else if (responseData["httpCode"] === 409) {
					toast.error(`${t("Error")}! This receipt has already been uploaded. Please upload another receipt.`);
					setSelectedFile([]);

					return true;
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);

					return false;
				}
			})
			.catch((err) => {
				setResetCaptchaValue(resetCaptchaValue + 1);
				console.log("error", err);
				props.setLoading(false);
				if (typeof err === "object") {
					if (err["response"]) {
						if (err["response"].httpCode === 409) {
							//duplicate Receipt message
							toast.error(`${t("Error")}! This receipt has already been uploaded. Please upload another receipt.`);
							setSelectedFile([]);
							return false;
						} else {
							toast.error(`${t("Error")}! ${t("Something went wrong")}`);
							return false;
						}
					} else {
						toast.error(`${t("Error")}! ${t("Something went wrong")}`);
						return false;
					}
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					return false;
				}
			});
	};
	const resetAllSelectionBtnClick = () => {
		setShow(false);
		setSelectedFile([]);
		setResetCaptchaValue(resetCaptchaValue + 1);
		setCaptchaValue(false);
	};
	return (
		<Container className="justify-content-md-center section-content">
			<div className="validate-purchase-div">
				<Col>
					<Row xs={1} md={1} lg={1}>
						<h1 className="section-heading heading text-center">
							<Trans>Upload your receipt</Trans>
						</h1>
					</Row>
					<Row xs={1} md={1} lg={1}>
						<div className="h2-disc1" role="heading" aria-level="2">
							<Trans>Take a clear picture of your entire receipt. The receipt must include the name and date of purchase on the photo.</Trans>
						</div>
						<p align="center">
							<Trans>
								Click on the box below to upload your receipt.
								{/* Images will be accepted in JPG, PNG, or PDF format per entry. */}
							</Trans>
						</p>
					</Row>
					<Row xs={1} md={1} lg={1}>
						<label className="label-disc small">
							{/* <Trans>Note</Trans>: <Trans>Size of the receipt must be less than</Trans> 5 MB. */}
							<Trans>Note</Trans>: Uploaded images should be the same format - JPG, PNG, or PDF are accepted. If a combination of images and PDF are selected only the last PDF will be uploaded.
						</label>
					</Row>
					{/* <Row className="justify-content-md-center">
						<Col md="12">
							<PreviousReceiptList reloadCounter={receiptLoadCounter} />
						</Col>
					</Row> */}
					<Row>
						<FileDropZone enableUploading={enableUploading} parentCallBack={setSelectedFile} selectedFile={selectedFile} />
					</Row>
					{captchaEnable ? (
						<Row md={1} className="mt-3">
							<Col>
								<Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
							</Col>
						</Row>
					) : (
						""
					)}
					{enableUploading ? (
						<Row md={2} xs={1} sm={2}>
							<Col className="text-right  upload-btn">
								<Button size="lg" type="button" onClick={uploadImage} className="btn btn-primary">
									<Trans>Submit</Trans>
								</Button>
							</Col>
							<Col className="upload-btn">
								<Button size="lg" type="button" onClick={resetAllSelectionBtnClick} className="btn btn-primary">
									<Trans>Reset</Trans>
								</Button>
							</Col>
						</Row>
					) : (
						""
					)}
				</Col>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(UploadReceipt, "Loading....");
