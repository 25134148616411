import React, { useState } from "react";
import { Container, Row, Col, Accordion, Card, Button, Nav, Section } from "react-bootstrap";
const FrequentlyAskedQuestions = () => {
	const [data, setData] = useState([
		{
			id: 0,
			clicked: false,
			name: "How long do I have to submit my receipt?",
			content: "<p>All products must be purchased between 11/13/2023 and 01/03/2024. You have until 01/10/2024 at 11:59PM ET to upload your receipt.</p>",
		},
		{
			id: 1,
			clicked: false,
			name: "What do I do if I am having trouble participating in the program?",
			content: "You can submit any questions you may have about the promotion to our <a href='/support' class='alink'>support box</a>.",
		},
		{
			id: 2,
			clicked: false,
			name: "What are the participating products?",
			content: "<p><a href='public/upccodes.pdf' target='new' class='alink'>Click here</a> to see the full list of participating products.</p>",
		},
		{
			id: 3,
			clicked: false,
			name: "How many times can I submit the same valid receipt?",
			content: "<p>You can only submit a valid receipt once, receipts submitted more than once will be rejected.</p>",
		},
		{
			id: 4,
			clicked: false,
			name: "How many rewards can I get?",
			content: "There is no limit to the number of rewards per person/email address, but each claim must be unique.",
		},
		{
			id: 5,
			clicked: false,
			name: "Do I have to purchase all $20 of participating products in one transaction?",
			content: "Yes, you must purchase $20 or more of participating products in one transaction between 11/13/2023 and 01/03/2024.",
		},
		{
			id: 6,
			clicked: false,
			name: "What do I do if I have additional questions?",
			content: "Review the <a href='/public/Coca-Cola-2023.pdf' target='new' class='alink'>Terms and Conditions</a> for the offer for additional details. If the <a href='/public/Coca-Cola-2023.pdf' target='new' class='alink'>Terms and Conditions</a> have not answered your question, please email us via the <a href='/support' class='alink'>support box</a>.",
		},
	]);
	const [faqStatus, setFaqStatus] = useState(false);
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	return (
		<Container className="justify-content-md-center section-content" id="faq-content">
			<section role="contentinfo" aria-label="FAQ">
				<Row className="justify-content-md-center section-content">
					<Col md={12}>
						{/* <h1 className="section-heading text-center mb-5">FREQUENTLY ASKED QUESTIONS</h1> */}
						<h1 className="main-heading section-heading text-center">FREQUENTLY ASKED QUESTIONS</h1>
					</Col>
					<Col md={12} className="text-right">
						<Button onClick={bulkActionHandler} variant="link" className="remove-underline">
							{!faqStatus ? "Open All" : "Close All"}
						</Button>
					</Col>
					<Col md={12} className="faq-page p-0">
						<Accordion>
							{data.map((v, i) => {
								return (
									<Card style={{ marginBottom: "16px" }}>
										<Card.Header>
											<Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" className="p-0" onClick={(e) => toggleHandler(i)} eventKey={v.id}>
												<Row style={{ cursor: "pointer" }}>
													<Col className="col-11">{v.name}</Col>
													<Col className="col-1 text-right pl-0 faq-line-icon">{v.clicked ? "-" : "+"}</Col>
												</Row>
											</Accordion.Toggle>
										</Card.Header>
										{v.clicked ? (
											<Accordion>
												<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
											</Accordion>
										) : (
											<Accordion.Collapse>
												<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
											</Accordion.Collapse>
										)}
									</Card>
								);
							})}
						</Accordion>
					</Col>
				</Row>
			</section>
		</Container>
	);
};

export default FrequentlyAskedQuestions;
