import React from "react";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import CopyRight from "./../CopyRight";
//import { useTranslation } from "react-i18next";
const Footer = () => {
	//const { t } = useTranslation();

	return (
		<footer>
			<Container fluid className="footer">
				<div className="footer-link" aria-label="footerMenu">
					<ul>
						<li>
							<a href="/public/Coca-Cola-2023.pdf" target="new">
								Terms and Conditions
							</a>
						</li>
						<li>|</li>

						<li>
							<a href="https://www.reyescocacola.com/images/docs/Reyes_Holdings_Global_Privacy_Notice-EN.pdf" target="new">
								Privacy Policy
							</a>
						</li>
					</ul>

				</div>
				<div class="copy-text">
					<p>2023® Reyes Coca-Cola Bottling LLC</p>
				</div>
				<CopyRight />
			</Container>
		</footer>
	);
};
export default connect(null, { getManu })(Footer);
